
import MenuTab from "@/components/common/MenuTab.vue";
import UiForm from "@/components/common/Form.vue";
import UiButton from "@/components/UI/Button";
import UiCheckbox from "@/components/UI/Checkbox";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import { defineComponent, ref, inject, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import qs from "qs";
import Loading from "@/components/UI/Loading/src/Loading.vue";

export default defineComponent({
  name: "PostTopic",
  components: { MenuTab, UiForm, UiButton, UiCheckbox, Loading },
  setup() {
    //  切换列表菜单
    const route = useRoute();
    const type = route.query.type || void 0;
    type Menu = "帖子" | "问答";
    const menu: Menu[] = type ? ["帖子"] : ["帖子", "问答"];
    const router = useRouter();
    const currentMenuItem = ref(menu[0]);
    let postType = route.query.postType || 0;

    if (route.query.postType) {
      currentMenuItem.value = menu[Number(postType)];
      console.log(currentMenuItem.value);
    }
    const publicReward = ref(false);
    function changeReward(event: boolean) {
      publicReward.value = event;
    }

    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
      switch (item) {
        case "帖子":
          postType = 0;
          break;
        case "问答":
          postType = 1;
          break;
      }
      router.push({
        name: "PostTopic",
        query: {
          postType,
        },
      });
    }
    const title = ref("");
    const content = ref("");
    const files = ref([]);
    let topicId = "";
    if (type) {
      topicId = route.query.id as string;
      axios
        .get("/M/Discover/EditPostTopics/" + topicId, {
          params: { validate: true },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data.obj.entity;
            title.value = data.title;

            // let contentData = data.content;
            let contentData = res.data.obj.topicDetail.topicContent
            contentData = contentData.replace(/[(<p> | </p> )]/g, "");
            contentData = contentData.replace(/[</p><p>]/g, "\n");
            content.value = contentData;

            const list = data.picLinks
              ? data.picLinks.split(",")
              : data.videos
              ? data.videos.split(",")
              : [];
            list.forEach((item: string) => {
              (files.value as any).push({
                src: item,
              });
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((e) => {
          Toast({
            type: "error",
            title: e.message || e,
          });
        });
    }

    const imageReg = /image\/(.+)/;
    const videoReg = /video\/(.+)/;
    const loading = ref(false);
    const loadOption = {
      text: "正在上传...",
      color: "#b9135a",
      textColor: "#b9135a",
    };
    function upload(uploadFiles: any[]) {
      return new Promise((resolve, reject) => {
        const fileEmpty = uploadFiles.length === 0;
        let url = "";

        let pics = "",
          videos = "",
          isImg = false,
          isVideo = false;
        let file = null;
        const map4Reg = /\.mp4/g;
        uploadFiles.forEach((item) => {
          if (item.file) {
            file = item.file;
          } else {
            if (map4Reg.test((item as any).src)) {
              videos = item.src;
            } else {
              pics = pics + (item.src ? item.src + "," : "");
            }
          }
        });
        if (fileEmpty || !file) {
          resolve({ pics, videos });
        }

        if (file && imageReg.test((file as any).type)) {
          url = "/M/FroalaApi/UploadImage";
          isImg = true;
        }
        if (file && videoReg.test((file as any).type)) {
          url = "/M/FroalaApi/UploadVideo";
          isVideo = true;
        }

        const formData = new FormData();
        uploadFiles.forEach((item) => {
          formData.append("file", item.file);
        });

        try {
          axios
            .post(url, formData, {
              timeout: 0,
            })
            .then((res) => {
              if (res.data.success) {
                if (isImg) {
                  pics = pics + res.data.returnUrl;
                }
                if (isVideo) {
                  videos = res.data.returnUrl;
                }
                resolve({ pics, videos });
              }
            });
        } catch (e) {
          Toast({
            type: "error",
            title: Object.prototype.toString.call(e),
          });
        }
      });
    }

    const userInfo = inject("userInfo") as any;
    const destoryKeepAlive = inject(
      "destoryKeepAlive"
    ) as () => Promise<unknown>;
    function submit() {
      const credit = 0;
      const _url = !type
        ? "/M/BBSServer/MDoPostTopic?rnd="
        : "/M/BBSServer/DoEditTopic?rnd=";
      const kid = 1;
      const formType = postType ? 1 : undefined;
      const score = publicReward.value ? 100 : 0;
      if (title.value == "") {
        Toast({
          title: "请输入标题！",
          type: "warning",
        });
        return false;
      }
      if (content.value == "") {
        Toast({
          title: "请输入帖子内容！",
          type: "warning",
        });
        return false;
      }
      loading.value = true;
      upload(files.value).then((res: any) => {
        const data = !type
          ? qs.stringify({
              kid,
              title: title.value,
              content: content.value,
              credit,
              type: formType,
              score,
              ...res,
            })
          : qs.stringify({
              id: topicId,
              title: title.value,
              content: content.value,
              type: formType,
              score,
              ...res,
            });
        axios
          .post(_url, data)
          .then((res) => {
            if (res.data.success) {
              loading.value = false;
              Toast({
                type: "success",
                title: res.data.msg,
                onClose: () => {
                  destoryKeepAlive().then(() => {
                    router.back();
                  });
                },
              });
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
          })
          .catch((err) => {
            loading.value = false;
            console.log("err");
          });
      });
    }

    watch(
      () => {
        return route.query;
      },
      (to, from) => {
        currentMenuItem.value = menu[Number(to.postType)];
      }
    );

    return {
      menu,
      currentMenuItem,
      selectMenu,
      title,
      content,
      files,
      submit,
      loading,
      loadOption,
      changeReward,
      publicReward,
      postType,
    };
  },
});
